
import {defineComponent, ref, onMounted, nextTick, computed} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      name: Yup.string().required(t(`${currentLanguage.value}.profile.name_is_a_required_field`)).label("Name"),
      email: Yup.string().min(4).required(t(`${currentLanguage.value}.messages.email_is_a_required_field`)).email(t(`${currentLanguage.value}.messages.email_is_a_required_field`)).label("E-mail"),
      password: Yup.string().min(8, t(`${currentLanguage.value}.messages.password_must_be_at_least_8_characters`)).required(t(`${currentLanguage.value}.messages.password_is_a_required_field`)).label("Password"),
      password_confirmation: Yup.string()
        .required(t(`${currentLanguage.value}.messages.password_confirmation_is_a_required_field`))
        .oneOf([Yup.ref("password"), null], t(`${currentLanguage.value}.messages.the_passwords_must_match`))
        .label("Password Confirmation"),
      toc: Yup.number().required(t(`${currentLanguage.value}.messages.you_must_accept_the_terms_and_conditions`)).label("Terms and Conditions"),
    });

    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    const onSubmitRegister = async (values) => {

       
      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");


        await ApiService.post("auth/register", values)
        .then(({ data }) => {
          if(data.success){
            Swal.fire({
              title: t(`${currentLanguage.value}.common.success`),
              text: t(`${currentLanguage.value}.messages.your_account_has_been_created_Please_check_your_e_mail_to_confirm_your_account`),
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            });

          }
          // eslint-disable-next-line
          submitButton.value!.disabled = false;

          // Remove indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
        

        })
        .catch(({ response }) => {

          if(response.data.success){
            Swal.fire({
              title: "Sucesso!",
              text: response.data.message,
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            });

          }
          else{
             Swal.fire({
                title: "Oops!",
                text: response.data.message,
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
              });
          }
         
            // eslint-disable-next-line
          submitButton.value!.disabled = false;

          // Remove indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
        

        });
     
    }
    const AuthProvider = (provider: string) => {
      router.push("/oauth/" + provider);
    };
    
    return {
      registration,
      onSubmitRegister,
      submitButton,
      currentLanguage,
      AuthProvider
    };
  },
});
